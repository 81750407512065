import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ContainerColumn } from '@components/styled';
import { BodyMedium, BodyMediumBold } from '@components/styled/Typography';
import { ReactComponent as ArrowIcon } from '@icons/custom/arrow-long-icon.svg';
import { formatUSD } from '@utils/numbers';
import { formatDateDefault } from '@utils/date-time';
import { numberToWord } from '@app/locale';
import useSubscriptionChange from '../../../../useSubscriptionChange';
const DetailsContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.m,
}));
const DetailsBlockContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 0.5,
    padding: `${props.theme.spacing_sizes.xs * 2.5}px ${props.theme.spacing_sizes.xs * 3}px`,
    boxSizing: 'border-box',
    backgroundColor: '#F8FAFF',
    borderRadius: 12,
}));
const DetailsTitle = styled(BodyMediumBold)(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.s,
    letterSpacing: '-0.224px',
    alignItems: 'center',
}));
const PrevValue = styled.span(props => ({
    color: props.theme.customColors.shades.primaryMidLight,
}));
const NewValue = styled.span(props => ({
    color: props.theme.palette.primary.main,
}));
const DetailsText = styled(BodyMedium)(props => ({
    letterSpacing: '-0.224px',
    color: props.theme.palette.text.primary,
}));
const getTradingTypes = (plan, t) => {
    var _a, _b, _c;
    const types = [];
    if ((_a = plan.metadata) === null || _a === void 0 ? void 0 : _a.spotCopy) {
        types.push(t('trading_type.SPOT', { ns: 'common' }));
    }
    if ((_b = plan.metadata) === null || _b === void 0 ? void 0 : _b.marginCopy) {
        types.push(t('trading_type.MARGIN', { ns: 'common' }));
    }
    if ((_c = plan.metadata) === null || _c === void 0 ? void 0 : _c.futuresCopy) {
        types.push(t('trading_type.FUTURES', { ns: 'common' }));
    }
    return types;
};
const getTradingTypesInText = (plan, t) => getTradingTypes(plan, t).join(' & ');
const getNewPlanMissingTradingTypesInText = (currentPlan, newPlan, t) => {
    const currentPlanTradingTypes = getTradingTypes(currentPlan, t);
    const newPlanTradingTypes = getTradingTypes(newPlan, t);
    const newPlanMissingTradingTypes = currentPlanTradingTypes.filter(p => !newPlanTradingTypes.includes(p));
    return newPlanMissingTradingTypes.join(' & ');
};
const getPlanPrice = (plan, t) => {
    const price = Number(plan.unitAmount);
    if (Number.isNaN(price)) {
        return 'undefined';
    }
    return price === 0 ?
        t('settings.tabs.subscription.subscription_plan.free') :
        formatUSD(price);
};
const DetailsBlock = ({ item }) => (_jsxs(DetailsBlockContainer, { children: [_jsxs(DetailsTitle, { children: [_jsx(PrevValue, { children: item.prev_value }), _jsx(ArrowIcon, {}), _jsx(NewValue, { children: item.new_value })] }), _jsx(DetailsText, { children: item.text })] }));
const ChangePlanDetails = () => {
    var _a, _b, _c;
    const { i18n, t } = useTranslation();
    const { currentPlan, newPlan, currentSubscription, isUpgradeStrategy, } = useSubscriptionChange();
    const portfoliosDetails = {
        prev_value: `${numberToWord(((_a = currentPlan.metadata) === null || _a === void 0 ? void 0 : _a.portfoliosLimit) || 0, t)} ${t('portfolio.portfolio')}`,
        new_value: `${numberToWord(((_b = newPlan.metadata) === null || _b === void 0 ? void 0 : _b.portfoliosLimit) || 0, t)} ${t('portfolio.portfolio')}`,
        text: isUpgradeStrategy ?
            t('settings.sidebars.change_subscription.steps.initial.upgrade_details.portfolios') :
            t('settings.sidebars.change_subscription.steps.initial.downgrade_details.portfolios')
    };
    const tradingTypeDetails = {
        prev_value: getTradingTypesInText(currentPlan, t),
        new_value: getTradingTypesInText(newPlan, t),
        text: isUpgradeStrategy ?
            t('settings.sidebars.change_subscription.steps.initial.upgrade_details.trading_type', { tradingTypes: getTradingTypesInText(newPlan, t) }) :
            t('settings.sidebars.change_subscription.steps.initial.downgrade_details.trading_type', {
                planName: (_c = newPlan.metadata) === null || _c === void 0 ? void 0 : _c.title.en,
                tradingTypesAllowed: getTradingTypesInText(newPlan, t),
                tradingTypesMissing: getNewPlanMissingTradingTypesInText(currentPlan, newPlan, t)
            })
    };
    const subscriptionPriceDetails = {
        prev_value: getPlanPrice(currentPlan, t),
        new_value: getPlanPrice(newPlan, t),
        text: t('settings.sidebars.change_subscription.steps.initial.subscription_start_date', {
            date: currentSubscription ?
                formatDateDefault(new Date(currentSubscription.currentPeriodEnd), i18n.language) :
                t('date_time.now', { ns: 'common' }).toString().toLowerCase()
        }),
    };
    return (_jsxs(DetailsContainer, { children: [_jsx(DetailsBlock, { item: portfoliosDetails }), _jsx(DetailsBlock, { item: tradingTypeDetails }), _jsx(DetailsBlock, { item: subscriptionPriceDetails })] }));
};
export default ChangePlanDetails;
